
import React, {useEffect, useState, useCallback, useContext} from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import './Login.page.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Navbar, Nav, Container, Tooltip } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import { AuthenticationService } from '../service/auth.service';
import { Outlet } from 'react-router-dom';

function Layout() {
    return (
      <div>
        <Outlet />
      </div>
    );
  }
  export default Layout;