import axios from 'axios';
import { AxiosRequestHeaders, AxiosResponse } from 'axios';

export class HttpClient {
    post(url, body, options)
    {
//        let headers = options['headers'];
        console.log(body);

        return axios.post(url, body, {headers: options['headers']});
    
    }

}
export class HttpParams {
    items;
    constructor() {
        this.items = [];
    }
    set(param, value) {
        this.items[param] = value;
        return this;
    }
}
export class HttpHeaders {
    items;
    constructor() {
        this.items = [];
    }
    set(param, value) {
        this.items[param] = value;
        return this;
    }
    getAll() {
        console.log(this.items);
        return this.items;
    }
}
