import React, { useState, useEffect } from "react";
import "./Main.css";
import { ThemeProvider } from "styled-components";
import {
  useMeetingManager,
  MeetingProvider,
  lightTheme,
  VideoTileGrid,
  useLocalVideo,
  Grid,
  MeetingStatus,
  useMeetingStatus,
  useRemoteVideoTileState,
  RemoteVideo,
  useVideoInputs,
  useRosterState,
  useSelectVideoQuality,
  useSelectAudioInputDevice,
  useSelectAudioOutputDevice,
  useBackgroundBlur,
  MicSelection,
  SpeakerSelection,
  CameraSelection,
  QualitySelection,
  PreviewVideo,
  Roster,
  RosterGroup,
  RosterAttendee,
  BackgroundBlurProvider,
  VideoInputBackgroundBlurControl
} from "amazon-chime-sdk-component-library-react";
import Button from 'react-bootstrap/Button';

import { AuthenticationService } from '../service/auth.service';
import { useAuth } from '../contexts/AuthContext';
//import { ChimeClient } from "@aws-sdk/client-chime";

/*
const AWS = require("aws-sdk");

const region = "ap-northeast-1";

const chime = new AWS.Chime({
  region: "us-east-1"
});

let gMeeting = [];
let gAttendee = [];
*/

// const MeetingManager = () => {
//   const meetingManager = useMeetingManager();
//   const [seq, setCount] = useState(0);
//   const joinMeeting = async () => {
//     // Fetch the meeting and attendee data from your server
//     //const response = await fetch("/my-server");
//     //const data = await response.json();
//     var data = [];
//     const method = "GET";
// //    await fetch('http://demo01.atsys.co.jp/test2.php?seq=' + seq, {
// //    await fetch('http://localhost:8080/aws/getAwsSession/' + seq, {
//     await fetch('https://www.workationspace.net/aws/getAwsSession/' + seq, {
//         method,
//     })
//       .then((response) => response.json())
//       .then((responseJson) => {
//         console.log(responseJson);
//         data = responseJson;
//       })
//       .catch((error) =>{
//         console.error(error);
//       });

// //    var data = require('./temp-join-config.json');
//     const joinData = {
//       meetingInfo: data.Meeting,
//       attendeeInfo: data.Attendee,
//     };

//     // Use the join API to create a meeting session
//     await meetingManager.join(joinData);

//     // At this point you can let users setup their devices, or start the session immediately
//     await meetingManager.start();
//   };
// /*  
//   useEffect(() => {
//     meetingManager.getAttendee = async (chimeAttendeeId, externalUserId) => {
//       //const response = await fetch('/my-attendees-endpoint');
//       console.log(chimeAttendeeId);
//       return {
//         name: 'chimeAttendeeId'
//       }
//     }
//   }, [meetingManager])
// */

//   return (
//     <div>
//       <p>seq: {seq} </p>
//       <button onClick={() => setCount(seq + 1)}>
//         seq plus
//       </button>
//       <button onClick={joinMeeting}>Join</button>
//     </div>
//   );
// };

const Recognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new Recognition();
recognition.lang = 'ja-JP';
recognition.interimResults = true;
recognition.continuous = true;
recognition.onend = function() {
  recognition.stop();
  isStarting = false;
  console.log('音声の検出が終わりました。');
  startRecognizer();
};
const startRecognizer = async () => {
  if (isStarting) {
    return;
  }
  recognition.start();
  console.log('start recognition');
  isStarting = true;
}
const stopRecognizer = async () => {
  recognition.stop();
}
let isStarting = false;

const authenticationService = AuthenticationService.getObject();
let mStatus = 0;
const MeetingManager = (props) => {
  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();
  const { isVideoEnabled, toggleVideo, setIsVideoEnabled } = useLocalVideo();
  // const { tiles } = useRemoteVideoTileState();

  mStatus = meetingStatus;
  const joinMeeting = async () => {
    // 既に接続済みの場合は終了
    console.log('meetingStatus:' + mStatus);
    if (mStatus==MeetingStatus.Succeeded || mStatus==MeetingStatus.JoinedFromAnotherDevice) {
      if (props.value.typed_cd == 'S') {
        // if (tiles.length==0) {
        //   await meetingManager.leave();
        //   return;
        // }
      }
      return;
    }
    var data = [];
    console.log('MeetingManager');
    await authenticationService.join(props.value.clientid, props.value.meetingid, props.value.typed_cd)
      .then((responseJson) => {
        console.log(responseJson);
        data = responseJson['data'];
      })
      .catch((error) =>{
        console.error(error);
      });
    
    // 接続情報を取得した場合のみ
    if (data.status == 'S') {
      const joinData = {
        meetingInfo: data.Meeting,
        attendeeInfo: data.Attendee,
      };
  
      // Use the join API to create a meeting session
      await meetingManager.join(joinData);
  
      // At this point you can let users setup their devices, or start the session immediately
      await meetingManager.start();

      // await toggleVideo();
    }
  };
  const updBlurFlg = async () => {
    await authenticationService.updBlurFlg(props.value.clientid, props.value.meetingid, props.value.typed_cd, '0');
  }
  
  useEffect(() => {
    // サテライトは定期的に実行
    if (props.value.typed_cd == 'S') {
      setInterval(joinMeeting, 1000 * 10);
    } else {
      joinMeeting();
    }
  },[]);

  return (
    <div>
      meetingStatus; {mStatus}<br />
      {props.value.typed_cd=='S' && (<button onClick={updBlurFlg}>
        Enable Video
      </button>)}
    </div>
  );
}
const LocalVideoToggle = () => {
  const { isVideoEnabled, toggleVideo, setIsVideoEnabled } = useLocalVideo();
  function handleEnableVideo() {
    if (!isVideoEnabled) {
      toggleVideo();
    }
  }
  setInterval(handleEnableVideo, 15000);

  return (
    <button onClick={toggleVideo}>
      {isVideoEnabled ? "Stop your video" : "Start your video"}
    </button>
  );
};

// const MyChild = () => {
//   const { tiles } = useRemoteVideoTileState();
//   console.log(tiles);
//   const videos = tiles.map(tileId => <RemoteVideo tileId={tileId} />);

//   return <>{videos}</>;
// };
/*
const MyChild = () => {
  const { devices, selectedDevice, selectDeviceError } = useVideoInputs();
  const items = devices.map(device => (
    <li key={device.deviceId}>{device.label}</li>
  ));

  return (
    <div>
      <p>Current Selected DeviceId: {selectedDevice}</p>
      <p>Devices</p>
      <ul>{items}</ul>
      {selectDeviceError && (<p>{selectDeviceError.message}</p>)}
    </div>
  );
};
/*
const createM = () => {
  const createMeetingUrl = async () => {
    const { Meeting } = await chime
      .createMeeting({
        ClientRequestToken: Date.now().toString(),
        MediaRegion: region,
      })
      .promise();
  
    const { Attendee } = await chime
      .createAttendee({
        MeetingId: Meeting.MeetingId,
        ExternalUserId: Date.now().toString(),
      })
      .promise();
    
    gMeeting = Meeting;
    gAttendee = Attendee;
    console.log(JSON.stringify({
      Meeting: Meeting,
      Attendee: Attendee,
    }, null, 2));
  };
  
  return <button onClick={createMeetingUrl}>create</button>;
};
*/
// const MyChild = () => {
//   const selectVideoQuality = useSelectVideoQuality();
//   return (
//     <div>
//       <button onClick={() => selectVideoQuality('360p')}> 360p video input quality </button>
//       <button onClick={() => selectVideoQuality('540p')}> 540p video input quality </button>
//       <button onClick={() => selectVideoQuality('720p')}> 720p video input quality </button>
//     </div>
//   );
// };
const Main = () => {
  const { currentUser } = useAuth();
  const [isBlur, setisBlur] = useState(false);
  const getBlurFLg = async () => {
    // 既に接続済みの場合は終了
    // if (mStatus==MeetingStatus.Succeeded || mStatus==MeetingStatus.JoinedFromAnotherDevice) {
    //   return;
    // }
    var data = [];
    await authenticationService.getBlurFlg(currentUser.clientid, currentUser.meetingid, currentUser.typed_cd)
      .then((responseJson) => {
        console.log(responseJson);
        data = responseJson['data'];
      })
      .catch((error) =>{
        console.error(error);
      });
    console.log(data);
    setisBlur(data.blur_flg=='1'?true:false);
    console.log(isBlur);
  };
  useEffect(() => {
    // セントラルは定期的に実行
    if (currentUser.typed_cd == 'C') {
      setInterval(getBlurFLg, 1000 * 10);
    } else {
      setInterval(getBlurFLg, 1000 * 10);
      startRecognizer();
    }
  },[]);
  let prevResultText;
  recognition.onresult = function (event) {
    const results = event.results;
    const resultText = results[results.length - 1][0].transcript.trim();
    if (prevResultText === resultText) {
      return;
    }
    prevResultText = resultText;
    console.log(resultText);
    if (resultText == 'ひらけごま') {
      console.log('=================== open ===================');
      authenticationService.updBlurFlg(currentUser.clientid, currentUser.meetingid, currentUser.typed_cd, '0');
    }
    // actionByResult(resultText);
    startRecognizer();
  }

  console.log(isBlur);
  return (
    <ThemeProvider theme={lightTheme}>
      <MeetingProvider>
        <Grid style={{ height: "95vh",filter: currentUser.typed_cd=='C' && isBlur?"blur(15px)":''}}>
          {currentUser.typed_cd=='S' && <Button variant={isBlur?"danger":"success"} style={{position:"absolute", zIndex:100,top:"10%",left:"90%"}}>{isBlur?"blur":"clear"}</Button>}
          <VideoTileGrid 
            noRemoteVideoView={<div>No one is sharing his video</div>}
          />
        </Grid>
        {/* <VideoInputBackgroundBlurControl /> */}
        {/* <MyChild /> */}
        <MeetingManager value={currentUser}/>
        <LocalVideoToggle />
        <CameraSelection />
        <SpeakerSelection />
        <MicSelection />
        <QualitySelection />
      </MeetingProvider>
    </ThemeProvider>
  );
};


export default Main;