import { useAuth } from '../../contexts/AuthContext';
// import { FC } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

const AuthenticatedGuard = ({ children }) => {
  const { currentUser } = useAuth(); // ログイン済みかどうかのフラグ
  console.log(currentUser)
  const location = useLocation();
  return currentUser ? <>{children}</> : <Navigate to="/login" state={{ from: location }} />;
};

export default AuthenticatedGuard;

// useAuthの部分のコーディングから始める 参考はAuthContext　https://zenn.dev/masalib/books/2d6e8470732c8b/viewer/b3963b
