//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/operator/map'
import { HttpClient, HttpParams, HttpHeaders } from '../shared/common/http-client';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
// import { AlertService } from './alert.service';
// import { Result } from '../class/result';

export class AuthenticationService {
  menu = new Subject();
  user = new Subject();
  dispName = new Subject();
  api_url = '';
  headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  http;
  static obj = new AuthenticationService();
  static getObject() {
    return AuthenticationService.obj;
  }
  constructor() {
    this.http = new HttpClient();
    this.api_url = process.env.REACT_APP_API_ENDPOINT;
  }
  login(username, password) {
    // let params = new HttpParams()
    //   .set('username', username)
    //   .set('password', password);
    let param = {
      username: username,
      password: password
    };
    // let headers = new HttpHeaders()
    //   .set('accept', 'application/json')
    //   .set('Content-Type', 'application/json');
    let headers = {
        'accept': 'application/json',
        'Content-Type': 'application/json',
    }
    return this.http.post(this.api_url + 'api/auth', JSON.stringify(param), {headers:headers})
      .then(async (response) => {
        console.log(response);
        let token = response['data']['token'];
        // let headers = new HttpHeaders()
        //   .set('Content-Type', 'application/x-www-form-urlencoded')
        //   .set('Authorization', 'Bearer ' + response['token']);
        let headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer ' + token
        };
        var params = new URLSearchParams();
        params.append('username', username);
        return await this.http.post(this.api_url + 'capi/user/getUserInfo', params, {headers:headers})
          .then((response) =>{
            if (response) {
              console.log(response);
              const msgcd = response['data']['msgcd'];
              if (msgcd === 'SUC') {
                console.log('SUC');
                response['data']['user']['password'] = password;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                sessionStorage.setItem('token', token);
                sessionStorage.setItem('screens', JSON.stringify(response['data']['screens']));
                sessionStorage.setItem('menu', JSON.stringify(response['data']['menu']));
                sessionStorage.setItem('user', JSON.stringify(response['data']['user']));
                this.menu.next({'menu': response['data']['menu']});
                this.user.next({'user': response['data']['user']});
              } else {
                console.log('ERR');
                // this.alertService.error('ユーザー名またはパスワードが間違っています', true);
              }
              return response;
            }
        });
      })
    .catch(this.handleError);
  }
  loginAgain() {
    let user = JSON.parse(sessionStorage.getItem('user'));

    let params = new HttpParams()
      .set('username', user.username)
      .set('password', user.password);
    let param = {
      username: user.username,
      password: user.password
    };
    let headers = new HttpHeaders()
      .set('accept', 'application/json')
      .set('Content-Type', 'application/json');
    return this.http.post(this.api_url + 'api/auth', JSON.stringify(param), {headers:headers})
      .then(async (response) => {
        let token = response['token'];
        sessionStorage.setItem('token', token);
        console.log('after set');
        return response;

      })
    .catch(this.handleError);
  }

  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('menu');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('screens');
    this.menu.next({'menu': []});
    this.user.next({'user': null});
  }
  handleError(error) {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error);
  }
  getMenu() {
    return this.menu.asObservable();
  }
  getUser() {
    return this.user.asObservable();
  }
  setDispName(dispName) {
    this.dispName.next({'dispName': dispName});
  }
  getDispName() {
    return this.dispName.asObservable();
  }
  join(clientid, meetingid, type_cd) {
    let token = sessionStorage.getItem('token');
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    };
    var params = new URLSearchParams();
    params.append('clientid', clientid);
    params.append('meetingid', meetingid);
    params.append('typed_cd', type_cd);
    return this.http.post(this.api_url + 'aws/getAwsSessionFromParam', params, {headers:headers});
  }
  getBlurFlg(clientid, meetingid, type_cd) {
    let token = sessionStorage.getItem('token');
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    };
    var params = new URLSearchParams();
    params.append('clientid', clientid);
    params.append('meetingid', meetingid);
    params.append('typed_cd', type_cd);
    return this.http.post(this.api_url + 'aws/getBlurFlgFromParam', params, {headers:headers});
  }
  updBlurFlg(clientid, meetingid, type_cd, blur_flg) {
    let token = sessionStorage.getItem('token');
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token
    };
    var params = new URLSearchParams();
    params.append('clientid', clientid);
    params.append('meetingid', meetingid);
    params.append('typed_cd', type_cd);
    params.append('blur_flg', blur_flg);
    return this.http.post(this.api_url + 'aws/updBlurFlgFromParam', params, {headers:headers});
  }
}
