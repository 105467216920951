import React, {useEffect, useState, useCallback, useContext} from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import './Login.page.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Navbar, Nav, Container, Tooltip } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import { AuthenticationService } from '../service/auth.service';
import { useNavigate } from 'react-router-dom';

// const login = async (username, password) => {
//   const authenticationService = new AuthenticationService();
//   // Fetch the meeting and attendee data from your server
//   //const response = await fetch("/my-server");
//   //const data = await response.json();

//   // const data = {
//   //   'username': username,
//   //   'password': password,
//   // };
//   // let headers = {
//   //   'accept': 'application/json',
//   //   'Content-Type': 'application/json',
//   // }
//   authenticationService.login(username, password).then(
//     (responseJson) => {
//       console.log(responseJson);
//     }
//   ).catch((error) =>{
//          console.error(error);
//        })
//   let response = [];
//   // await axios.post('http://localhost:8080/api/auth', JSON.stringify(data), {headers: headers})
// //  await axios.post('https://www.workationspace.net/api/auth', JSON.stringify(data), {headers: headers})
//     // .then((responseJson) => {
//     //   console.log(responseJson);
//     // })
//     // .catch((error) =>{
//     //   console.error(error);
//     // });
// };
const handleChange = () => {};
const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});
function LoginForm() {
  const { currentUser, login } = useAuth();
  let navigate = useNavigate();
  console.log(currentUser);
  // if (sessionStorage.getItem('user')) {
  //   let authenticationService = AuthenticationService.getObject();
    
  //   navigate('/main', { replace: true });
  // }

/*  const handleSubmit = (event, errors) => {
    console.log(errors);
    return false;
  };*/

  return (
    <Container>
    <h1>ログイン</h1>
    <Formik
      validationSchema={schema}
      onSubmit={(values, errors) => {
        console.log(values);
        login(values.username, values.password).then(result => {
          console.log(result);
          navigate('/main', { replace: true });
        })
      }}
      initialValues={{
        username: '',
        password: '',
      }}
      
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>ユーザー名</Form.Label>
            <Form.Control 
              type="text"
              name="username"
              value={values.username}
              onChange={handleChange}
              isInvalid={!!errors.username}
              placeholder="ユーザー名を入力してください"/>
            <Form.Control.Feedback type="invalid">
              ユーザー名を入力してください
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>パスワード</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              isInvalid={!!errors.password}
              placeholder="パスワードを入力してください" />
            <Form.Control.Feedback type="invalid">
              パスワードを入力してください
            </Form.Control.Feedback>
          </Form.Group>
          <Button variant="primary" type="submit">
            ログイン
          </Button>
        </Form>
      )}
    </Formik>
    </Container>
  );
}
/*
function LoginForm() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    console.log(event);
    event.preventDefault();
    event.stopPropagation();
  setValidated(true);
  };
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      UserName: Yup.string()
        .required('Required'),
      Password: Yup.string()
        .required('Required'),
    }),
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <Container>
      <h1>ログイン</h1>
      <Form noValidate validated={validated} onSubmit={formik.handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>username</Form.Label>
          <Form.Control type="text" placeholder="Enter username" required/>
          <Form.Control.Feedback type="invalid">
            ユーザー名を入力してください
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Password" />
        </Form.Group>
        <Button variant="primary" type="submit">
          ログイン
        </Button>
      </Form>
    </Container>
  )
}*/

export default LoginForm;
