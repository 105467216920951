import React, { useContext, useState, useEffect } from "react";
// import { auth } from "../firebase";
import { AuthenticationService } from '../service/auth.service';

const AuthContext = React.createContext();
const authenticationService = AuthenticationService.getObject();
export function useAuth() {
 return useContext(AuthContext);
}

export function AuthProvider({children}) {
  const [currentUser, setCurrentUser] = useState(null);

  function login(email, password) {
    return authenticationService.login(email, password);
  }
  function logout() {
    return authenticationService.logout();
  }
  const value = {
    currentUser: currentUser,
    login,
    logout
  };
  useEffect(() => {
    // Firebase Authのメソッド。ログイン状態が変化すると呼び出される
    // auth.onAuthStateChanged((user) => {
    authenticationService.getUser().subscribe(
        result => {
            console.log(result);
            setCurrentUser(result.user);
        }
    );
    // });
  }, []);
  return (
    <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
);
}
