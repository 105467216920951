import React, {useEffect, useState} from 'react';

import './Admin.css';

function Admin() {
  return (
    <h1>管理</h1>
  )
}

export default Admin;
